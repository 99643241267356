import { createApp } from "vue";
import { createPinia } from "pinia";
import { createAuth0 } from "@auth0/auth0-vue";
import App from "./App.vue";
import router from "./router";
// import { authConfig } from "../auth_config.js";
import piniaPersist from "pinia-plugin-persist";

import "./assets/styles/main.scss";

// const { domain, client_id } = authConfig;

const app = createApp(App);

app.use(router);
app.use(
  createAuth0({
    domain: "hybrone.eu.auth0.com",
    clientId: "WbA028tuRcfFLKamzaeFHdK0QgV94Zfx",
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://tropi-api.com",
    },
  })
);

const pinia = createPinia();
pinia.use(piniaPersist);

app.use(pinia);

app.mount("#app");
