<template>
  <div class="container">
    <header-component></header-component>
    <section class="has-text-centered">
      <h2 class="title">Merhaba</h2>
      <p class="content has-text-centered">
        Dönemsel olarak gerçekleştirilen Davranışsal Yetkinlik Değerlendirme
        sürecine hoşgeldin.<br />
        Değerlendirme sürecine başlamak için şirket mail hesabınla oturum
        açmalısın.
      </p>

      <button
        class="button is-link login"
        v-if="!isAuthenticated"
        @click="login"
      >
        Oturum Aç
      </button>
      <router-link
        class="button is-link continue"
        v-if="isAuthenticated"
        to="/evaluations"
        >Devam
      </router-link>
      <button
        class="button is-text logout"
        v-if="isAuthenticated"
        @click="logout"
      >
        Oturum Kapat
      </button>
    </section>
    <footer-component></footer-component>
  </div>
</template>
<style lang="scss" scoped>
.container {
  max-width: 940px;
  margin: 0 auto;
}
.button.login,
.button.logout,
.button.continue {
  width: 220px;
  height: 44px;
}
section {
  min-height: 400px;
}
</style>
<script>
import { useStore } from "@/store/";
import { useAuth0 } from "@auth0/auth0-vue";
import HeaderComponent from "@/components/Header";
import FooterComponent from "@/components/Footer";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  setup() {
    const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
    const store = useStore();

    return {
      logout: () =>
        logout({
          returnTo: window.location.origin,
        }),
      login: () => {
        loginWithRedirect({
          appState: { target: "/evaluations" },
        });
      },
      user,
      isAuthenticated,
      store,
    };
  },
};
</script>
