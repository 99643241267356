<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-half is-4 has-text-link has-text-weight-semibold">
        {{
          evaluated.id === currentUser.id
            ? "KENDİNE VERDİĞİN PUAN"
            : evaluated.name
        }}
      </div>
      <div class="column is-one-quarter">
        {{ rateText }}
      </div>
      <div class="column is-relative">
        <StarRating
          v-model:rating="rateValue"
          @click:rating="ratePerson"
          :star-size="20"
          :glow="5"
          :animate="true"
          :read-only="readOnly"
        />

        <button
          :class="[
            'button',
            'spinner',
            'is-ghost',
            readOnly ? 'is-loading' : '',
          ]"
          disabled
        ></button>
      </div>
      <div
        class="column is-four-fifths"
        v-if="rateValue === 1 || rateValue === 5"
      >
        <textarea
          class="textarea"
          :note-id="'note' - ratingId"
          v-model="note"
          placeholder="Bu puanı verme nedenini kısaca buraya yazmanı rica ediyoruz."
          @blur="updateNote"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.spinner {
  position: absolute;
  top: 5px;
  left: 130px;
  cursor: default !important;
  color: #fff;
  border: 0;
}
.spinner:focus {
  outline: none;
}

.spinner::-moz-focus-inner {
  border: 0;
}
.columns {
  max-width: 700px;
  margin: 0 auto;
}
</style>

<script>
import { useStore } from "@/store/";
import StarRating from "vue-star-rating";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  data() {
    return {
      readOnly: false,
      isLoading: false,
    };
  },
  setup() {
    const { getAccessTokenSilently } = useAuth0();
    const store = useStore();
    return {
      store,
      getToken: async () => {
        if (store.token) {
          let token = await getAccessTokenSilently();
          store.token = token;
        }

        return store.token;
      },
    };
  },
  props: ["question", "evaluated", "ratingId"],
  components: {
    StarRating,
  },
  name: "RatingComponent",
  computed: {
    currentUser() {
      return this.store.currentUser;
    },
    rateText() {
      const arrRates = [
        "Beklenenin çok altında",
        "Beklenenin altında",
        "Beklentiyi karşılıyor",
        "Beklenenin üstünde",
        "Beklenenin çok üstünde",
      ];
      return arrRates[this.rateValue - 1];
    },
    rateValue: {
      get() {
        return this.ratings.find((rating) => {
          return rating.id === this.ratingId;
        })?.rating;
      },
      set(val) {
        this.ratePerson(val);
      },
    },
    note: {
      get() {
        return this.ratings.find((rating) => rating.id === this.ratingId)?.note;
      },
      set(val) {
        let target = this.ratings.find((rating) => rating.id === this.ratingId);
        target.note = val;
      },
    },
    ratings() {
      return this.store.ratings;
    },
  },
  methods: {
    async ratePerson(val) {
      this.readOnly = true;
      let rateData = {
        question: this.question,
        evaluated: { id: this.evaluated.id, email: this.evaluated.email },
        rating: val,
      };
      const token = await this.getToken();
      this.store.rate(rateData, token).then(() => {
        this.readOnly = false;
      });
    },
    async updateNote() {
      if (!this.note?.length) return;
      this.isLoading = true;
      let noteData = {
        ratingId: this.ratingId,
        note: this.note,
      };
      const token = await this.getToken();
      this.store
        .updateNote(noteData, token)
        .then(() => {
          console.log("note updated");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
