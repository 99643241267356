<template>
  <div>
    <button @click="login">Log in</button>
    <button @click="logout">Log out</button>
  </div>
</template>
<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const { loginWithRedirect, logout } = useAuth0();

    return {
      logout: () =>
        logout({
          returnTo: window.location.origin,
        }),
      login: () => {
        loginWithRedirect();
      },
    };
  },
};
</script>
