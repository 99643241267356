import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/LoginView.vue";
import Welcome from "@/views/WelcomeView.vue";
import Section from "@/views/SectionView.vue";
import Evaluations from "@/views/EvaluationsView.vue";
import Completed from "@/views/CompletedView.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/evaluations",
    name: "Evaluations",
    component: Evaluations,
  },
  {
    path: "/sections",
    name: "360",
    component: Section,
  },
  {
    path: "/completed",
    name: "Completed",
    component: Completed,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
