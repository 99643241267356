<template>
  <div class="container">
    <header-narrow></header-narrow>

    <question-component section="currentSection" />

    <div
      v-if="questions.length > 1"
      class="additional-comments has-text-centered"
    >
      <p class="has-text-center mb-4">
        Bu bölümle ilgili eklemek istediklerini buraya yazabilirsin.
      </p>
      <div class="level">
        <div class="level-item">
          <textarea
            class="textarea"
            v-model="sectionNote"
            placeholder=""
          ></textarea>
        </div>
        <div class="level-item">
          <button
            :class="['button', 'is-link', isSaving ? 'is-loading' : '']"
            @click="saveNote"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
    <div class="buttons" v-if="questions.length > 1">
      <button
        :class="['button', 'is-link', isLoading ? 'is-loading' : '']"
        @click="previousSection"
        :disabled="currentSection === 1 || isLoading"
      >
        ÖNCEKİ BÖLÜM
      </button>
      <button
        :class="['button', 'is-link', isLoading ? 'is-loading' : '']"
        @click="nextSection"
        :disabled="currentSection === 5 || isLoading"
      >
        SONRAKİ BÖLÜM
      </button>
      <button
        class="button is-link"
        v-if="currentSection === 5"
        @click="complete"
      >
        TAMAMLA
      </button>
    </div>
  </div>
</template>
<style scoped>
.buttons {
  display: flex;
  justify-content: center;
}
.additional-comments {
  max-width: 700px;
  margin: 2rem auto;
}
section {
  max-width: 672px;
  margin: 60px auto;
}
.container {
  max-width: 940px;
  margin: 0 auto;
}
</style>
<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "@/store";
import QuestionComponent from "@/components/Question.vue";
import HeaderNarrow from "@/components/HeaderNarrow.vue";

export default {
  data() {
    return {
      isSaving: false,
    };
  },
  components: {
    QuestionComponent,
    HeaderNarrow,
  },
  computed: {
    sectionNote: {
      get() {
        //find sectionnote by currentSection
        const sectionNote = this.sectionNotes.find(
          (sectionNote) => parseInt(sectionNote.section) === this.currentSection
        );
        return sectionNote ? sectionNote.note : "";
      },
      set(value) {
        //find sectionnote by currentSection
        const sectionNote = this.sectionNotes.find(
          (sectionNote) => parseInt(sectionNote.section) === this.currentSection
        );
        if (sectionNote) {
          sectionNote.note = value;
        } else {
          this.sectionNotes.push({
            section: this.currentSection,
            note: value,
          });
        }
      },
    },
    sectionNotes() {
      return this.store.sectionNotes;
    },
    isLoading() {
      return this.store.isSectionLoading;
    },
    currentSection() {
      return this.store.currentSection;
    },
    questions() {
      return this.store.questions;
    },
    totalRatings() {
      return this.store.evaluations.length * this.questions.length;
    },
    answers() {
      return this.store.ratings;
    },
    numberOfAnswers() {
      return this.answers.length;
    },
  },
  methods: {
    complete() {
      if (this.numberOfAnswers !== this.totalRatings) {
        alert(
          "Değerlendirmeyi tamamlamak için tüm puanlamaları bitirmen gerekiyor."
        );
        return;
      }
      if (
        this.store.ratings.some(
          (rating) =>
            rating.rating === 5 &&
            (typeof rating.note === "undefined" || rating.note == "")
        )
      ) {
        alert("5 veya 1 puan verdiğinde bir açıklama girmelisin. ");
        return;
      }

      this.$router.push("/completed");
    },
    nextSection() {
      if (this.numberOfAnswers !== this.totalRatings) {
        alert("Diğer bölüme geçmeden önce bu bölümü tamamlaman gerekiyor.");
        return;
      }
      if (
        this.store.ratings.some(
          (rating) =>
            rating.rating === 5 &&
            (typeof rating.note === "undefined" || rating.note == "")
        )
      ) {
        alert("5 veya 1 puan verdiğinde açıklama girmelisin.");
        return;
      }
      this.getToken().then((token) => {
        this.store.nextSection(token);
      });
    },
    previousSection() {
      this.getToken().then((token) => {
        this.store.previousSection(token);
      });
    },
    async saveNote() {
      this.isSaving = true;
      let token = await this.getToken();
      this.store
        .saveSectionNote(this.currentSection, this.sectionNote, token)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
  setup() {
    const { getAccessTokenSilently } = useAuth0();
    const store = useStore();

    return {
      store,

      getToken: async () => {
        if (store.token) {
          let token = await getAccessTokenSilently();
          store.token = token;
        }

        return store.token;
      },
    };
  },
};
</script>
