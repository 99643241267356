<template>
  <div>
    <div class="progressbar">
      <div class="progressbar-fill" :style="{ width: progress + '%' }"></div>
    </div>
    <div class="level">
      <div class="level-left">
        <div class="level-item py-3">
          <img class="logo" :src="tropiLogo" />
        </div>
      </div>
      <div class="level-right">
        <div class="level-item tropi-linear has-text-weight-semibold is-size-5">
          Davranışsal Yetkinlik Değerlendirme
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import tropiLogo from "@/assets/tropi-logo.svg";

import { useStore } from "@/store/";

export default {
  name: "HeaderComponent",
  data() {
    return {
      tropiLogo,
    };
  },
  computed: {
    questions() {
      return this.store.questions;
    },
    numberOfQuestions() {
      return this.store.numberOfQuestions;
    },
    totalRatings() {
      return this.store.evaluations.length * this.numberOfQuestions;
    },
    answers() {
      return this.store.ratings;
    },
    numberOfAnswers() {
      return this.answers.length;
    },
    progress() {
      return ((this.numberOfAnswers / this.totalRatings) * 100).toFixed(0);
    },
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
};
</script>

<style scoped>
.progressbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #c4c4c4;
  z-index: 99;
}
.progressbar-fill {
  transition: all 1.5s ease-in-out;
  height: 10px;
  background: #00d1b2;
  width: 0%;
}
.logo {
  height: 36px;
}
hr {
  margin-top: 0px;
  height: 4px;
  background: #fee2bf;
}
.level-item {
  margin: 50px 32px 0px 32px;
}
.level {
  margin-bottom: 0px;
}
</style>
