<template>
  <div>
    <br />
    <ul>
      <li v-for="item in evaluations" :key="item.id">
        <rating-component
          :question="question"
          :evaluated="item"
          :rating-id="question + currentUser.id + item.id"
        />
      </li>
    </ul>
  </div>
</template>
<style scoped>
.columns {
  max-width: 700px;
  margin: 0px auto;
}
</style>

<script>
import { useStore } from "@/store/";
import RatingComponent from "@/components/Rating.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  props: ["question"],
  components: {
    RatingComponent,
  },
  name: "EvaluationsComponent",
  computed: {
    questions() {
      return this.store.questions;
    },
    currentUser() {
      return this.store.currentUser;
    },
    currentSection() {
      return this.store.currentSection;
    },
    evaluations() {
      return this.store.evaluations;
    },
  },
};
</script>
