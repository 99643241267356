<template>
  <div class="has-text-centered foot">
    <img :src="hybroneLogo" class="my-1" />
    <br />
    <span class="has-text-weight-bold has-text-grey-light"
      >Davranışsal Yetkinlik Değerlendirme</span
    >
    <br />
    <span class="has-text-weight-bold has-text-grey-light">2022</span>
  </div>
</template>
<style>
.foot {
  margin: 20px auto;
}
</style>

<script>
import hybroneLogo from "@/assets/hybrone.svg";

export default {
  name: "HeaderComponent",
  data() {
    return {
      hybroneLogo,
    };
  },
};
</script>

<style scoped>
img {
  height: 30px;
}
</style>
