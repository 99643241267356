<template>
  <div>
    <section class="has-text-centered">
      <h1 class="title is-2 tropi-linear">
        {{ currentSectionName }}
      </h1>
      <h1 class="subtitle has-text-grey-light">{{ progress }}% tamamlandı</h1>
      <!-- <p class="content"> -->
      <!--   Bu bölüm yedi sorudan oluşuyor.<br />Anahtar kelimeler: İşbirliği, -->
      <!--   Sorumluluk, Paylaşım, Planlı Çalışma, İletişim, Çözüm Odaklılık, İş -->
      <!--   Odaklılık -->
      <!-- </p> -->
    </section>
    <hr />

    <ul v-if="isVisible">
      <li v-for="item in questions" :key="item.id">
        <div class="container">
          <img :src="playBullet" class="bullet" /><span>{{
            item.question
          }}</span>
        </div>
        <evaluations-component :question="item.id" />
        <hr />
      </li>
    </ul>
  </div>
</template>
<style scoped>
.container {
  max-width: 800px;
}
hr {
  height: 1px;
  margin: 50px 0 30px 0;
  background: #c4c4c4;
}
section {
  max-width: 740px;
  margin: 56px auto;
}
ul {
  max-width: 750px;
  margin: 0px auto;
}
.bullet {
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
}
</style>

<script>
import { useStore } from "@/store/";
import EvaluationsComponent from "@/components/Evaluations.vue";

import { useAuth0 } from "@auth0/auth0-vue";
import playBullet from "@/assets/play.svg";

export default {
  data() {
    return {
      playBullet,
    };
  },
  setup() {
    const { getAccessTokenSilently } = useAuth0();

    const store = useStore();
    return {
      store,
      getToken: async () => {
        if (store.token) {
          let token = await getAccessTokenSilently();
          store.token = token;
        }

        return store.token;
      },
    };
  },
  components: {
    EvaluationsComponent,
  },
  name: "QuestionComponent",
  computed: {
    isVisible() {
      return (
        this.store.questions.length > 0 && this.store.questions[0].id != ""
      );
    },
    questions() {
      return this.store.questions;
    },
    numberOfQuestions() {
      return this.store.numberOfQuestions;
    },
    currentSection() {
      return this.store.currentSection;
    },
    totalRatings() {
      return this.store.evaluations.length * this.numberOfQuestions;
    },
    answers() {
      return this.store.ratings;
    },
    numberOfAnswers() {
      return this.answers.length;
    },
    currentSectionName() {
      return this.store.currentSectionName;
    },
    progress() {
      return ((this.numberOfAnswers / this.totalRatings) * 100).toFixed(0);
    },
  },
  mounted: async function () {
    const token = await this.getToken();
    this.store.fetchQuestions(token);
    this.store.fetchSectionRatings(token);
  },
};
</script>
