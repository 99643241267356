<template>
  <div class="container">
    <header-component></header-component>

    <section class="has-text-centered">
      <h2 class="title">Selam {{ user.given_name }}</h2>
      <p>
        Üç farklı çalışma arkadaşını ve kendini değerlendirmen için sana bazı
        sorular soruyoruz. Yapman gereken çok basit, ilgili sorular için her bir
        arkadaşına yıldız vermek. Unutma, 1 Yıldız ve 5 Yıldız epey iddaalı
        puanlar, bu yüzden neden böyle düşündüğünü açıklaman harika olur.
      </p>

      <ul v-if="evaluationsExist">
        <p class="content">
          İşte bu dönemde değerlendireceğin çalışma arkadaşların:
        </p>
        <li
          class="has-text-link has-text-weight-bold"
          v-for="e in evaluationsExceptMe"
          :key="e.evaluated_name"
        >
          {{ e.name }}
        </li>
      </ul>

      <div v-if="evaluationsExist">
        <div v-if="evaluations.length < 5">
          <p class="content mt-4">
            Dilersen bir çalışma arkadaşını daha listeye ekleyebilirsin.
          </p>
          <div class="field has-addons my-6">
            <div class="control">
              <div class="select">
                <select v-model="newEvaluation">
                  <option value="">Opsiyonel Değerlendirme</option>
                  <option
                    v-for="user in availableUsers"
                    :key="user.email"
                    :value="user.email"
                  >
                    {{ user.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="control">
              <button
                :class="['button', 'is-link', isLoading ? 'is-loading' : '']"
                @click="addUser"
              >
                Ekle
              </button>
            </div>
          </div>
        </div>
        <p class="mt-4">
          Değelendirme süreci beş bölümden oluşuyor.<br />Şimdi hazırsan hemen
          başlayabiliriz.
        </p>
        <router-link
          class="button is-link continue"
          to="/sections"
          v-if="evaluationsExist"
        >
          HAZIRIM!</router-link
        >
      </div>
      <p v-else class="mt-4 notification is-warning">
        Değerlendirme süreci henüz hazır değil.
      </p>
    </section>
    <footer-component></footer-component>
  </div>
</template>
<style scoped>
.field.has-addons {
  justify-content: center;
}
li {
  line-height: 30px;
}
section {
  max-width: 672px;
  margin: 60px auto;
}
.button.continue {
  margin-top: 30px;
  width: 220px;
  height: 44px;
}
.container {
  max-width: 940px;
  margin: 0 auto;
}
</style>
<script>
import { useStore } from "@/store/";
import { useAuth0 } from "@auth0/auth0-vue";
import HeaderComponent from "@/components/Header";
import FooterComponent from "@/components/Footer";

export default {
  components: { HeaderComponent, FooterComponent },
  data() {
    return {
      newEvaluation: "",
      isLoading: false,
    };
  },
  methods: {
    addUser() {
      if (!this.newEvaluation) {
        return;
      }
      this.isLoading = true;
      this.getToken().then((token) => {
        try {
          this.store.recordEvaluation(this.newEvaluation, token);
          setTimeout(() => {
            this.store.fetchEvaluations(token);
            this.isLoading = false;
          }, 1000);
        } catch (e) {
          console.log(e);
          this.isLoading = false;
        }
      });
    },
  },
  computed: {
    evaluationsExist() {
      return this.evaluations.length > 0 && this.evaluations[0].id != "";
    },
    evaluationsExceptMe() {
      return this.store.evaluations.filter((e) => e.email !== this.user.email);
    },
    evaluations() {
      return this.store.evaluations;
    },
    users() {
      return this.store.users;
    },
    availableUsers() {
      return this.users.filter((user) => {
        return !this.evaluations.find((e) => e.email === user.email);
      });
    },
  },
  setup() {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const store = useStore();

    return {
      user,
      isAuthenticated,
      store,
      getToken: async () => {
        store.token = store.token || (await getAccessTokenSilently());

        return store.token;
      },
    };
  },
  mounted() {
    this.getToken()
      .then((token) => {
        console.log("token", token);
        this.store.fetchEvaluations(token);
        this.store.fetchUsers(token);
        this.store.updateUserInfo(token);
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>
