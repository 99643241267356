<template>
  <div>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <img :src="tropiLogo" />
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <img :src="artwork1" />
        </div>
      </div>
    </div>

    <hr />
  </div>
</template>

<script>
import tropiLogo from "@/assets/tropi-logo.svg";
import artwork1 from "@/assets/artwork-1.svg";

export default {
  name: "HeaderComponent",
  data() {
    return {
      tropiLogo,
      artwork1,
    };
  },
};
</script>

<style scoped>
hr {
  margin-top: 0px;
  height: 4px;
  background: #fee2bf;
}
.level-left {
  padding-top: 50px;
}
.level-item {
  margin: 50px 32px 0px 32px;
}
.level {
  margin-bottom: 0px !important;
}
</style>
