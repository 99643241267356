import { defineStore } from "pinia";
import http from "@/libs/http";

export const useStore = defineStore("main", {
  state: () => {
    return {
      questions: [{ id: "", question: "", category: "", value: "" }],
      evaluations: [{ id: "", email: "", name: "" }],
      currentSection: 1,
      currentUser: {
        id: "",
        name: "",
        email: "",
        token: "",
      },
      ratings: [{ id: "", rating: 0 }],
      users: [{ name: "", email: "" }],
      sectionNotes: [{ section: "0", note: "" }],
      isSectionLoading: false,
      token: "",
    };
  },
  getters: {
    currentSectionName: (state) => {
      return state.questions[0].category;
    },
    numberOfQuestions: (state) => {
      return state.questions.length;
    },
  },
  actions: {
    nextSection(token) {
      this.currentSection < 6 && this.currentSection++;
      this.fetchQuestions(token);
      this.fetchSectionRatings(token);
    },
    previousSection(token) {
      this.currentSection > 1 && this.currentSection--;
      this.fetchQuestions(token);
      this.fetchSectionRatings(token);
    },
    async fetchQuestions(accessToken) {
      this.isSectionLoading = true;
      const response = await http.get(`/questions/${this.currentSection}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      this.questions = response.data;
      this.isSectionLoading = false;
      try {
        const notes = await http.get(`/sections/${this.currentSection}/note`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const sectionNote = notes.data;
        const index = this.sectionNotes.findIndex(
          (note) => note.section === sectionNote.section
        );
        if (index > -1) {
          this.sectionNotes[index] = sectionNote;
        } else {
          this.sectionNotes.push(sectionNote);
        }
      } catch (error) {
        console.log("No notes for this section");
      }
    },
    async updateUserInfo(accessToken) {
      try {
        const res = await http(`/users/info`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.currentUser.id = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchUsers(acccessToken) {
      await http(`/users`, {
        headers: {
          Authorization: `Bearer ${acccessToken}`,
        },
      }).then((response) => {
        this.users = response.data;
      });
    },
    async fetchEvaluations(accessToken) {
      http(`/users/evaluations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          this.evaluations = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchSectionRatings(accessToken) {
      http(`/users/ratings/${this.currentSection}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          this.ratings = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchRatings(accessToken) {
      this.ratings = [];
      this.ratings = await http(`/users/ratings`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => response.data);
    },
    async rate(rateData, accessToken) {
      if (!accessToken) {
        return;
      }
      const response = await http.post("/evaluations", rateData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          let newRating = response.data.evaluation;
          //replace the old rating with the new one
          const index = this.ratings.findIndex(
            (rating) => rating.id === newRating.id
          );
          if (index > -1) {
            this.ratings[index] = newRating;
          } else {
            this.ratings.push(newRating);
          }

          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    },
    async updateNote(noteData, accessToken) {
      const response = await http.patch("/evaluations", noteData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          // let newRating = response.data.evaluation;
          // for (let i = 0; i < this.ratings.length; i++) {
          //   if (this.ratings[i].id === newRating.id) {
          //     this.ratings.splice(i, 1);
          //   }
          // }
          // this.ratings.push(newRating);
          resolve("done");
        } else {
          reject("Error");
        }
      });
    },
    async recordEvaluation(email, accessToken) {
      return http.post(
        `/users/evaluations`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },
    async saveSectionNote(section, note, accessToken) {
      return http.post(
        `/sections/${section}/note`,
        {
          note,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    async checkComplete(accessToken) {
      return http.get("/users/complete", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ["currentUser"],
      },
    ],
  },
});
