<template>
  <div class="container">
    <header-component></header-component>
    <section v-if="isComplete" class="has-text-centered">
      <h2 class="title">Tebrikler {{ user.given_name }}!</h2>
      <p class="content has-text-centered">
        Değerlendirmeyi tamamlayarak harika bir iş çıkardın. Unutmadan
        söyleyelim, sadece üst yöneticilerin görebileceği puanlama ve yorumlar
        gizli kalmalı.
      </p>
      <p>Bir sonraki değerlendirmede görüşmek üzere.</p>
      <img class="m-6" :src="wavingHand" />
      <br />
      <button class="button is-link" @click="logout">OTURUMU KAPAT</button>
    </section>
    <section v-else class="has-text-centered">
      <h2 class="title">Form doğrulanıyor...</h2>
      <p class="content has-text-centered">
        Bu işlem sadece bir kaç saniye sürecektir. Eğer bir sorun olduğunu
        düşünüyorsan yöneticilerin ile irtibata geçebilirsin.
      </p>
      <button class="button is-link" @click="logout">OTURUMU KAPAT</button>
    </section>
    <footer-component></footer-component>
  </div>
</template>
<style scoped>
section {
  max-width: 600px;
  margin: 0 auto;
}
</style>
<script>
import { useAuth0 } from "@auth0/auth0-vue";
import HeaderComponent from "@/components/Header";
import FooterComponent from "@/components/Footer";
import wavingHand from "@/assets/waving-hand.svg";
import { useStore } from "@/store";

export default {
  data() {
    return {
      isComplete: false,
      wavingHand,
    };
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
  setup() {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    const store = useStore();

    return {
      store,
      user,
      getToken: async () => {
        if (store.token) {
          let token = await getAccessTokenSilently();
          store.token = token;
        }

        return store.token;
      },
      logout: () =>
        logout({
          returnTo: "http://localhost:8080/",
        }),
    };
  },
  mounted: async function () {
    const token = await this.getToken();
    this.store.checkComplete(token).then((res) => {
      this.isComplete = res.data === "Completed";
    });
  },
};
</script>
